@use "@/styles/constants/devices.scss" as *;

.imageWrapper {
  margin-right: 4px;
  width: 17px;
}

.offerButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  background-color: var(--betslip-labelBackgroundColor);
  border: 1px solid var(--betslip-labelPrimaryColor);
  color: var(--betslip-labelPrimaryColor);
  border-radius: 4px;
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;

  @media only screen and (min-width: $mobile) {
    padding: 2px 4px;
  }
}

.offerButton__fastBet {
  @extend .offerButton;
  padding: 3px 8px;
  background-color: var(--betslip-fastBetLabelBackgroundColor);
  border: 1px solid var(--betslip-fastBetLabelPrimaryColor);
  color: var(--betslip-fastBetLabelPrimaryColor);
}

.offerDisplay {
  padding: 1rem 0.5rem;
  background-color: var(--betslip-labelBackgroundColor);
  border: 1px solid var(--betslip-labelPrimaryColor);
  color: var(--betslip-labelPrimaryColor);
  width: 250px;
  border-radius: 4px;
  position: absolute;
  top: 90%;
  right: 1rem;
  z-index: 6;
  flex-direction: column;
  align-items: flex-start;
}
.offerDisplay__fastBet {
  @extend .offerDisplay;
  background-color: var(--betslip-fastBetLabelBackgroundColor);
  border: 1px solid var(--betslip-fastBetLabelPrimaryColor);
  color: var(--betslip-fastBetLabelPrimaryColor);
}

.header {
  font-weight: 700;
  margin: 0px 0px 0.5rem;
}

.paragraph {
  margin-bottom: 0px;
}
