.selectionArticle {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed var(--betslip-selectionBorderBottomColor);
  .lastArticle {
    border-bottom: "none";
  }
}

.selectionWrapper {
  flex-grow: 4;
  padding-left: 1rem;
}

.selectionHeading {
  font-size: 0.875rem;
  margin: 1rem 0;
}

.selectionEventContainer {
  font-size: 0.75rem;
  padding-bottom: 1.5rem;
}

.selectionMarketName {
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0.5rem;
}

.selectionTime {
  margin-left: 1rem;
}

.selectionActionButtonsContainer {
  display: flex;
}
.selectionActionButton {
  max-width: 2rem;
  display: flex;
  font-size: 0;
  color: var(--contentHeaderFontColor);
  background-color: var(--betslip-disabledButtonBackgroundColor);
  height: 2rem;
  width: 2rem;
  padding: 0.5rem;
  margin-right: 1rem;
  border-radius: 50%;
  border: none;
  justify-content: center;
  align-self: center;
  transform: rotate(0);
  transition: all 0.3s ease-in-out;

  svg {
    font-size: 1rem;
  }
}

.selectionActionButton-isDisabled {
  @extend .selectionActionButton;
  background-color: var(--betslip-enabledButtonBackgroundColor);
  transform: rotate(-45deg);
}

.removeSelectionButton {
  min-width: 0;
  width: 0;
  background-color: var(--betslip-disabledButtonBackgroundColor);
  color: var(--contentHeaderFontColor);
  font-size: 0;
  border: none;
  margin: 0;
  padding: 0;
  transition: all 0.3s ease-in-out;
}

.removeSelectionButton-isDisabled {
  @extend .removeSelectionButton;
  min-width: 60px;
  width: 60px;
  font-size: 0.75rem;
}
