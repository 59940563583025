.bookieDisplayContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  justify-content: space-between;
  border-bottom: 1px solid var(--betslip-selectionBorderBottomColor);
  position: relative;
  height: 99px;
}

.betContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 40%;
}

.oddsWrapper {
  display: flex;
  align-items: center;
}

.odds {
  color: var(--betType-fontColor);
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0px auto 0px 0.75rem;
}

.ctaContainer {
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
}

.offersContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  flex-flow: row wrap;
  margin-left: auto;
  justify-content: space-between;
  align-content: space-evenly;
  flex-direction: row-reverse;
  grid-gap: 4px;
}

.bookmakerButton {
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
}

.circle {
  margin-left: 16px;
  padding: 0px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  border: none;
  background: var(--betslip-highlight);
}

.arrowRight {
  fill: var(--betslip-background);
}
