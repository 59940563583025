@use "@/styles/constants/devices.scss" as *;

.enableTipsterStyling {
  .stakeSelectorContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 20px 16px 16px;
    font-size: 0.6875rem;
    font-weight: 500;
    align-items: center;
    span {
      margin: 0 8px 0 0;
    }

    @media (min-width: $smallMobile) {
      span {
        margin-right: 15px;
      }
    }
    @media only screen and (min-width: $tablet) {
      font-size: 0.8125rem;
      justify-content: space-between;
    }
  }

  .stakeSelector {
    display: flex;
    border: 1px solid var(--tipsterBetBuilder-secondaryBorderColor);
    border-radius: 4px;
    font-weight: 700;

    & button:first-child {
      border-radius: 8px 0 0 8px;
    }

    & button {
      font-size: 0.675rem;
      font-family: var(--font-montserrat);
      font-weight: 700;
      background: var(--tipsterBetBuilder-backgroundColor);
      border: none;
      color: var(--tipsterBetBuilder-buttonFontColor);
      border-right: 1px solid var(--tipsterBetBuilder-secondaryBorderColor);
      cursor: pointer;
      padding: 8px 4px;
      display: flex;
      justify-content: center;
      @media only screen and (min-width: $smallMobile) {
        font-size: 0.75rem;
        padding: 0.525rem;
        width: 100%;
      }
    }

    & button:last-child {
      border-radius: 0 8px 8px 0;
      border-right: none;
    }

    & [aria-pressed="true"] {
      background: var(--tipsterBetBuilder-activeButtonColor);
      color: var(--tipsterBetBuilder-activeButtonFontColor);
      border-radius: 0 !important;
    }

    & .enableTipsterStyling {
      & [aria-pressed="true"] {
        background: var(--tipsterBetBuilder-activeButtonColor);
        color: var(--tipsterBetBuilder-activeButtonFontColor);
        border-radius: 0 !important;
      }
    }
  }

  .enableStatsTipsterStyling {
    margin-right: auto;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (min-width: $smallMobile) {
      flex: 1;
    }
  }
}

.disableTipsterStyling {
  &.stakeContainer {
    display: flex;
    flex-direction: row;
    padding: 0 1rem 1rem 1rem;
    border-bottom: var(--betslip-betStakeBorder);
  }

  .stakeSelectorContainer {
    display: flex;
    flex-direction: column;
    width: 60%;
  }

  .stakeLabel {
    font-size: 0.75rem;
    color: var(--betslip-highlight);
    margin-bottom: 0.5rem;
  }

  .stakeSelector {
    display: flex;
    border: 1px solid var(--betslip-highlight);
    border-radius: 4px;
    font-weight: 700;
    width: 100%;
    height: 34px;
    margin-right: auto;
  }

  .styledButton {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    background: var(--betslip-background);
    border-top: none;
    border-bottom: none;
    border-left: none;
    color: var(--betslip-highlight);
    border-right: 1px solid var(--betslip-highlight);
    padding: 0.525rem;
    cursor: pointer;
    width: 25%;
    justify-content: center;

    &:first-child {
      border-radius: 2px 0 0 2px;
    }

    &:last-child {
      border-radius: 0 2px 2px 0;
      border-right: none;
    }

    &:focus {
      outline: none;
    }

    &[aria-pressed="true"] {
      background: var(--betslip-highlight);
      color: var(--betslip-background);
    }
  }
}

// The styles below are not used by the Tipster so we do not give them the data-use-tipster-styling property
// and select them as usual
.stakeInputContainer {
  width: 35%;
  margin-left: auto;
  display: flex;
  flex-direction: column;
}

.inputLabel {
  font-size: 0.75rem;
  color: var(--betslip-highlight);
  margin-bottom: 0.5rem;
}

.inputWrapper {
  position: relative;
  margin: 0;
  display: flex;
  flex: 1 1 0;
  -webkit-box-pack: center;
  justify-content: center;
  min-height: 32px;

  &::before {
    // Added content attribute to both input wrapper div and the ::before pseudo selector
    // Content attribute has no effect outside the ::before selector but is added here to test which
    // currency symbol is being used because testing pseudo selectors is not supported in React Testing Library
    content: attr(data-before-content);
    font-size: 0.75rem;
    margin: auto;
    color: var(--betslip-highlight);
    position: absolute;
    top: 50%;
    left: 0.5rem;
    transform: translateY(-50%);
  }
}

.styledInput {
  padding: 0.5rem 0.5rem 0.5rem 1.2rem;
  background: var(--betslip-secondary);
  color: var(--betslip-highlight);
  border-radius: 4px;
  border: none;
  width: 100%;
  font-size: 0.75rem;
}
