.betType__container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  text-align: center;
  background-color: var(--betType-backgroundColor);
}

.betType {
  background-color: var(--betType-backgroundColor);
  color: var(--betType-defaultText);
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0 1rem 1rem 1rem;
  &.isEmptySelections {
    font-size: 1rem;
    opacity: 0.85;
  }
  &.underlined {
    border-bottom: 1px solid var(--betSlip-selectionBorderBottomColor);
  }
}
