.inputWrapper {
  position: relative;
  margin: 0;
  display: flex;
  flex: 1 1 0;
  -webkit-box-pack: center;
  justify-content: center;
  min-height: 32px;
  max-width: 100px;

  &::before {
    // Added content attribute to both input wrapper div and the ::before pseudo selector
    // Content attribute has no effect outside the ::before selector but is added here to test which
    // currency symbol is being used because testing pseudo selectors is not supported in React Testing Library
    content: attr(data-before-content);
    font-size: 0.75rem;
    margin: auto;
    color: var(--betslip-highlight);
    position: absolute;
    top: 50%;
    left: 0.5rem;
    transform: translateY(-50%);
  }
}

.input {
  padding: 0.5rem 0.5rem 0.5rem 1.2rem;
  background: var(--betslip-secondary);
  color: var(--betslip-highlight);
  border-radius: 4px;
  border: none;
  width: 100%;
  font-size: 0.75rem;
}
